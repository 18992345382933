<template>
  <div>

<div class="pt-1 pb-0 card-body alert-heading bg-primary bg-lighten-1 text-white rounded">
  <div><h4 class="pt-0 pb-0 mb-0 text-white">{{customer_name}}</h4></div>

<div class="pt-0 mt-0 pb-1 mb-1" v-if="$i18n.locale ==='tr'">
 <strong>   Satın alma geçmişinde <b>{{ customer_sales_products.length }}</b> adet ürün bulundu </strong>
</div>
<div class="pt-0 mt-0 pb-1 mb-1" v-if="$i18n.locale ==='en'" >
  <strong>   {{ customer_sales_products.length }} products found in purchase history</strong>
</div>
</div>
<div v-for="(product , index) in customer_sales_products"
        :key="index">
      <PurchaseHistoryCustomerCard :product="product" @cancelPayment="cancelPayment($event.val,$event.index)"/>
    
  </div>

    <!-- <template v-if="customer_sales_products.length === 0">
      <b-row class="d-flex justify-content-center align-items-center text-center">
        <p class="make-appointment-title">{{ $t('No Products Found In Purchase History') }}</p>
      </b-row>
      <b-row class="d-flex justify-content-center align-items-center">
        <b-img
            class="select-product-svg"
            src="@/assets/images/badge/purchased-search.svg" fluid></b-img>
      </b-row>
      <b-row class="d-flex justify-content-center">
         <b-button
            variant="primary" class="my-1 d-block"
           :to="{name: 'facilityshop',params: {personID: person_id }}">{{ $t("Sell Products") }}
        </b-button>
      </b-row>
    </template> -->
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

import moneyFormat from '@/functions/MoneyFormat'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PurchaseHistoryCustomerCard from '@/components/PurchaseHistoryCustomerCard/FacilityPurchaseHistoryCustomerCard'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    PurchaseHistoryCustomerCard,
    // BSV
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BCardFooter,
    BCardHeader,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCollapse,
    VBToggle,
    BAlert
  },
  data() {
    return {
      merchant_payment_id:null,
      customer_name:"",
      customer_sales_products: [],
      customer_sales_product: {
        quantit: "",
        unit_price: "",
        tax_rate: "",
        total_price: "",
        discount_rate: "",
        discounted_total_amount: "",
        total_amount_after_tax: "",
        payment_is_canceled:"",
        created_date: "",
        updated_date: "",
        title: "",
        description: "",
        name: "",
        activity_color: "",
        last_name: "",
      },
      person_id:null,
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "230k",
          subtitle: "Sales",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "8.549k",
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "1.423k",
          subtitle: "Products",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "$9745",
          subtitle: "Revenue",
          customClass: "",
        },
      ],
    };
  },
  methods: {
    dateFormat: (val) => {
      if (val != null) {
        let sonucDeger;
        if (typeof val !== 'undefined') {
          let date = val.split('T')[0].split('-').reverse().join('.')
          let hour = val.split('T')[1].split('+')[0]
          let hourPart = hour.split(':')
          hour = hourPart[0] + ':' + hourPart[1] + ':' + hourPart[2].split('.')[0]
          sonucDeger = date + ' ' + hour
        } else {
          sonucDeger = "Information Not Available"
        }
        return sonucDeger
      } else {
        return "Information Not Available"
      }
    },
    getCustomerProducts(person_id,company_branch_id) {
      this.$database.PurchasedService.get_customer_products(person_id,company_branch_id).then((res) => 
      {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
            if(this.status =="True")
            {

              this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("İşleminiz başarılı bir şekilde gerçekleşti"),
                icon: "CheckIcon",
                variant: "success",
              },
              })
            }
            
            if(this.status =="False")
            {
               this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("İşleminiz gerçekleşmedi"),
                icon: "CrossIcon",
                variant: "danger",
              },
              })
            }

          this.customer_name= res.Result.CustomerPerson.FirstName +" "+res.Result.CustomerPerson.LastName
          res.Result.CustomerProductSaleViews.map(el => {
            this.customer_sales_products.push({
             // ...el,

             customer_product_sales_id:   el.CustomerProductSalesId,
                product_priceId: el.ProductPriceId,
                person_id:  el.PersonId,
                title:el.ProductName,
                activity_name:el.ActivityName,
                quantity:  el.Quantity,
                session: el.Session,
                activity_consumed_count:el.ActivityConsumedCount,
                unit_price :el.UnitPrice,
                tax_rate:  el.TaxRate,
                total_price : el.TotalPrice,
                trainer_name: el.TrainerName,
                payment_type_id: el.PaymentTypeId,
                discount_rate: el.DiscountRate,
                expiry_date: el.ExpiryDate,              
                created_format: this.$t(this.dateFormat(el.CreatedDate)),
                updated_date:  el.UpdatedDate,
                discounted_total_amount : moneyFormat(el.DiscountedTotalAmount),
                total_amount_price: el.TotalPrice,
                payment_confirm : el.PaymentConfirm,
                payment_is_canceled_status:  el.PaymentIsCanceledStatus
              // total_amount_price: moneyFormat(el.DiscountedTotalAmount),
              // created_format: this.$t(this.dateFormat(el.CreatedDate))
            })
          })
        }
      });
    }
  },
  created() {
      
      
this.person_id = this.$route.params.personID;

  var company_branch_id=  this.$store.getters["auth/userInfo"].company_branch_id
    this.getCustomerProducts(this.person_id ,company_branch_id);
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/purchased.scss" scoped>

   .card-body {
     padding: 5px !important;
}

</style>
